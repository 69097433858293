.loginDiv {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.textDiv {
    width: 90%;
    background-color: rgba(228,227,227, 0.5);
}
.loginForm {
    padding: 15px;
    height: 80%;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5px ;
}

.inputForm {
    height: 15%;
    width: 100%;
    max-width: 500px;
    max-height: 60px;
    border-radius: 5px;
    text-align: center;
    font-size: large;
    margin-top: 5%;
}

.buttonForm {
    background-color: red;
    border: none;
    border-bottom: 3px solid black;
    border-radius: 5px;
    text-align: center;
    font-size: x-large;
    color: white;
    height: 15%;
    width: 100%;
    transition: all 0.2s;
    max-width: 500px;
    max-height: 60px;
    margin-top: 10%;
}

.buttonForm:hover{
    cursor: pointer;
}

.buttonForm:active{
    transform: translateY(1px)
}

@media (max-height: 550px)  {
    .inputForm {
        height: 25%;
    }

    .buttonForm {
        height: 25%;
    }

    .loginForm {
        padding: 0px;
        width: 80%;
    }
}

@media (max-width: 550px) {
    .loginForm {
        justify-content: center;
    }
}