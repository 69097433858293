.downloadDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.listsDiv {
    height: 75%;
}

.catalogueDiv {
    height: 25%;
}

.listTitle {
    color: white;
    display: flex;
    justify-content: center;
    width: 80%;
    padding-left: 20%;
}

.catalogueTitle {
    display: flex;
    height: 30%;
    justify-content: center;
    align-items: center;
    background-color: red;
    color: white;
}

.sectionList {
    width: 100%;
    height: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: space-around;
}

.sectionCatalogue {
    width: 100%;
    height: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: space-around;
}

.buttonDownload {
    background-color: rgb(244, 244, 244);
    display: flex;
    align-items: center;
    width: 14%;
    padding: 1px;
    border-radius: 5px;
}

.buttonDownload:hover{
    cursor: pointer;
    background-color: rgb(215, 213, 213);;
}

.downloadImg {
    width: 25%;
}

.titleDiv {
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: row;
    background-color: red;
    border-radius: 5px 5px 0px 0px;
    align-items: center;
}



@media (max-width: 750px) {
    .listTitle {
        font-size: larger;
    }

    .catalogueTitle {
        font-size: larger;
    }

    .downloadImg {
        width: 22%;
    }

    .buttonDownload {
        font-size: x-small;
     }
    
}

@media (max-width: 530px) {

    .downloadImg {
        width: 20%;
    }

    .buttonDownload {
       font-size: xx-small;
    }
}

@media (max-width:450px) {
   
    .buttonDownload {
        width: 20%;
    }
}