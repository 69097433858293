.mainDiv {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: rgb(228, 227, 227);
}

.header {
    height: 22%;
    width: 100%;
}

.content {
    height: 74%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentMain{
    height: 90%;
    width: 90%;
    max-width: 1000px;
    max-height: 600px;
    margin: auto;
    border: 1px solid black;
    border-radius: 5px;
    background-image: linear-gradient(rgba(255,255,255,0.7), rgba(255,255,255,0.7)), url('../img/Logo\ RR\ Render.png');
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
}

.footer {
    height: 4%;
    width: 100%;
}