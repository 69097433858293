.divTitle {
    display: flex;
    height: 75%;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.title {
    margin: 0;
    font-size: 8em;
    color: rgb(95, 95, 95);
}

.acronym {
    color: rgb(95, 95, 95);
    font-size: 0.3em;
}

.logo {
    height: 95%;
}

@media (max-width: 1000px) {
    .title {
        font-size: 6em;
    }
}

@media (max-width: 750px) {
    .title {
        font-size: 4em;
    }
}

@media (max-width: 550px) {
    .title {
        font-size: 2em;
    }
}
