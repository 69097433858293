
.individualList {
    width: 35%;
    max-height: 15%;
}

.buttonList {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color: black;
    align-items: center;
    border: 1px solid black;
    border-radius: 5px;
    background-color: white;
}

.buttonList:hover {
    cursor: pointer;
    background-color: rgb(228, 227, 227);
}

.listImg {
    width: 10%;
    margin-right: 10px;
}

@media (max-width: 500px) {
    .individualList {
        width: 45%;
    }

    .buttonList p{
        font-size: smaller;
    }
}

@media (max-width: 70px) {
    .individualList {
        width: 40%;
    }

    .buttonList p{
        font-size: small;
    }
}