.aboutDiv {
    padding: 15px;
    display: flex;
    width: 70%;
    height: 100%;
    flex-direction: column;
    margin: 0px auto;
    align-items: center;
    justify-content: space-around;
    background-color: rgba(255, 255, 255, 0.5);
}

.map {
    width: 90%;
    height: 70%;
}

@media (max-width: 500px) {
    .aboutDiv {
        padding-top: 15px;
        width: 94%;
    }

    .map {
        width: 95%;
        height: 60%;
    }
}