.individualCatalogue {
    width: 35%;
    max-height: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.individualCatalogue:hover {
    cursor: pointer;
    background-color: rgb(228, 227, 227);
}

.buttonCatalogue {
    width: 100%;
    height: 100%;
    padding: 10px;
    border: 1px solid black;
    border-radius: 5px;
    background-color: white;
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color: black;
    align-items: center;
}

.buttonCatalogue:hover{
    cursor: pointer;
    background-color: rgb(228, 227, 227);
}

.catalogueImg {
    width: 10%;
    margin-right: 10px;
}

@media (max-width: 500px) {
    .individualCatalogue {
        width: 45%;
    }

    .individualCatalogue p{
        font-size: smaller;
    }
}