.navBar {
    height: 25%;
    margin: 0px;
    border-top: 2px solid red;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(95, 95, 95);
}

.title {
    font-size: xx-large;
    color: white;
}

.titleLink1 {
    font-size: xx-large;
    color: white;
    text-decoration: none;
    margin-right: 2%;
}

.titleLink1:hover {
    color: rgb(255, 77, 77);
}

.titleLink1Active {
    font-size: xx-large;
    color: rgb(255, 77, 77);
    text-decoration: none;
    margin-right: 2%;
}


.titleLink2 {
    font-size: xx-large;
    color: white;
    text-decoration: none;
    margin-left: 2%;
}

.titleLink2:hover {
    color: rgb(255, 77, 77)
}

.titleLink2Active {
    font-size: xx-large;
    color: rgb(255, 77, 77);
    text-decoration: none;
    margin-left: 2%;
}
@media (max-width: 750px) {
    .titleLink1{
        font-size: x-large;
    }

    .titleLink2{
        font-size: x-large;
    }
    
    .titleLink1Active{
        font-size: x-large;
    }

    .titleLink2Active{
        font-size: x-large;
    }

    .title {
        font-size: x-large;
    }
}

@media (max-width: 500px) {
    .titleLink1{
        font-size: larger;
    }

    .titleLink2{
        font-size: larger;
    }

    .titleLink1Active{
        font-size: larger;
    }

    .titleLink2Active{
        font-size: larger;
    }

    .title {
        font-size: larger;
    }
}