.divContainer {
    width: 100vw;
    height: 100vh;
}

p{
    margin:0;
    font-size: larger;
    font-weight: bolder;
    text-align: center;
}

@media (max-width: 750px) {
    p {
        font-size: large;
    }
}

@media (max-width: 400px) {
    p {
        font-size: medium;
    }
}